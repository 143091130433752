import logoLink from '../resources/bwb.svg';

export const cssVariables = {
    active: '#89B740',
    inactive: '#CCC',

    primary_light: '#dce9c6',
    primary: '#89B740',
    primary_dark: '#6e9233',
    text_on_primary: '#fff',
    primary_transparent: '#f3f8ec',

    secondary: '#0072B8',
    secondary_light: '#dce9c6',
    text_on_secondary: 'white',
    secondary_transparent: '#F7F7F7',

    font_color: 'black',
    link_color: '#89B740',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#89B740',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#0072B8',    

    dropdown_background: '#cee5b2',

};

export const logo = logoLink;
